<template>
  <div
    style="
      overflow-x: scroll;
      overflow-y: scroll;
      margin-left: auto;

      margin: auto;
      max-height: 90vh;
    "
  >
    <button
      style="margin-top: 10px; margin-bottom: 10px"
      @click="showTablePortion()"
      class="btn btn-success"
      id="btnShowTable"
    >
      Порции по классам на диапазон
    </button>
    <br />
    <button
      style="margin-top: 10px; margin-bottom: 10px"
      @click="showTableMarks()"
      class="btn btn-success"
      id="btnShowTable"
    >
      Количество отправленных "отметок" <br />
      по классам на диапазон
    </button>

    <div>
      <button
        style="margin-left: 20px; margin-right: 10px"
        target="_blank"
        @click="newPrintStat()"
        class="btn btn-secondary"
      >
        Печать
      </button>
      <button
        @click="pickTable()"
        style="padding: 2px; background-color: rgba(95, 158, 160, 0.249)"
      >
        <svg
          style="margin-top: -3px"
          height="20"
          viewBox="0 0 48 48"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24h-24z" fill="none" />
          <path
            d="M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z"
          />
        </svg>

        <br />
        <div style="font-size: 12px; margin-top: -5px">copy</div>
      </button>
    </div>
    <div id="tablePrint">
      <table class="table">
        <tr>
          <th rowspan="2" style="position: sticky; border: 1px">
            <p style="width: 50px">Класс</p>
          </th>
          <th rowspan="2" style="position: sticky">
            <p style="width: 70px; font-size: 12px">Уровень образования</p>
          </th>
          <th rowspan="2" style="width: 150px; position: sticky">
            <p style="width: 90px">Смена</p>
          </th>

          <th
            v-for="(month, index) in printTableHead"
            :key="index"
            v-bind:colspan="month.day.length"
          >
            {{ month.monthName }}
          </th>
        </tr>
        <tr>
          <th
            id="THdays"
            v-for="(day, index) in days"
            :key="index"
            style="writing-mode: bt-rl"
          >
            <p>{{ day.day }}</p>
          </th>
        </tr>
        <tbody>
          <tr v-for="(stud, index) in tableBodyPrint" :key="index">
            <td
              style="
                position: sticky;
                left: 0;
                z-index: 1;
                background-color: inherit;
              "
            >
              {{ stud.className }}
            </td>
            <td>
              {{ stud.level }}
            </td>
            <td style="width: 150px">{{ stud.shift }}</td>
            <td
              v-for="(day, index) in stud.days"
              :key="index"
              style="font-size: 12px"
            >
              <div v-if="day.count">
                <div v-if="day.count > 0">
                  {{ day.count }}
                </div>
                <div
                  v-else
                  style="
                    background-color: rgba(255, 0, 0, 0.413);
                    padding: 2px 2px 2px 2px;
                  "
                >
                  {{ day.count }}
                </div>
              </div>
              <div v-if="day.countMark">{{ day.countMark }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["classList", "marks"],
  data() {
    return {
      printTableHead: [],
      printTable: [],
      tableBodyPrint: [],
      dates: [],
      days: [],
    };
  },
  methods: {
    pickTable() {
      let target = document.getElementById("tablePrint");
      let rng, sel;
      if (document.createRange) {
        rng = document.createRange();
        rng.selectNode(target);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(rng);
      } else {
        rng = document.body.createTextRange();
        rng.moveToElementText(target);
        rng.select();
      }

      document.execCommand("copy");
    },
    fullClear() {
      this.printTableHead = [];
      this.printTable = [];
      this.tableBodyPrint = [];
      this.dates = [];
      this.days = [];
    },
    showTablePortion() {
      this.fullClear();
      this.dates = this.getDateMonthAndDay(this.marks); //список дат оригинальных
      this.printTableHead = this.smashDates(this.dates); //разбивает на месяцы и дни
      this.tableBodyPrint = this.countPortionsByClass(
        this.marks,
        this.classList,
        this.days
      );
    },
    showTableMarks() {
      this.fullClear();
      this.dates = this.getDateMonthAndDay(this.marks); //список дат оригинальных
      this.printTableHead = this.smashDates(this.dates); //разбивает на месяцы и дни
      this.tableBodyPrint = this.countMarksByClass(
        this.marks,
        this.classList,
        this.days
      );
    },

    newPrintStat() {
      let auth = localStorage.getItem("user");
      let data = new Date().toLocaleDateString();

      let top =
        "<head>" +
        "</head><body onload='window.print()'><h2> МБОУ СОШ №24 (Система ACT)</h2> <h3>Порции на " +
        data +
        "</h3>";
      let printHtml = document.getElementById("tablePrint").innerHTML; // Получаем содержимое узла для печати

      let style =
        "<style type='text/css'>.table {border: 1px solid black; border-collapse: collapse; font-size:10px}  th,td { padding: 2px;border: 1px solid black; } </style> ";

      top += printHtml;
      top += style;

      top +=
        "<footer><h3>Директор МБОУ СОШ №24 &nbsp &nbsp&nbsp&nbsp&nbsp_________ &nbsp&nbsp&nbsp&nbsp&nbsp&nbspН.А. Голеницкая</h3></footer>";

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      tab.document.write(top);
      // tab.document.close();
      tab.focus();
      localStorage.setItem("user", auth);

      // tab.print();

      //   tab.onfocus = function () {
      //     setTimeout(function () {
      //       tab.close();
      //     }, 500);
      //   };
    },
    countPortionsByClass(marks, classList, days) {
      let tempArr = [];
      for (let i = 0; i < classList.length; i++) {
        let tempDays = [];
        for (let j = 0; j < days.length; j++) {
          tempDays.push({
            count: 0,
            day: days[j].day,
            month: days[j].month,
          });
        }
        classList[i].days = tempDays;
        tempArr.push(classList[i]);
      }
      marks = marks.filter((mark) => mark.countEating > 0);

      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < marks.length; j++) {
          if (marks[j].classID === tempArr[i].classID) {
            let day = Number(marks[j].date[0] + marks[j].date[1]);
            let month = Number(marks[j].date[3] + marks[j].date[4]) - 1;

            for (let c in tempArr[i].days) {
              if (
                month === tempArr[i].days[c].month &&
                day === tempArr[i].days[c].day &&
                marks[j].classID === tempArr[i].classID
              ) {
                tempArr[i].days[c].count += marks[j].countEating;
              }
            }
          }
        }
      }
      return tempArr;
    },
    countMarksByClass(marks, classList, days) {
      let tempArr = [];
      for (let i = 0; i < classList.length; i++) {
        let tempDays = [];
        for (let j = 0; j < days.length; j++) {
          tempDays.push({
            day: days[j].day,
            month: days[j].month,
            countMark: 0,
          });
        }
        classList[i].days = tempDays;
        tempArr.push(classList[i]);
      }

      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < marks.length; j++) {
          if (marks[j].classID === tempArr[i].classID) {
            let day = Number(marks[j].date[0] + marks[j].date[1]);
            let month = Number(marks[j].date[3] + marks[j].date[4]) - 1;

            for (let c in tempArr[i].days) {
              if (
                month === tempArr[i].days[c].month &&
                day === tempArr[i].days[c].day &&
                marks[j].classID === tempArr[i].classID
              ) {
                tempArr[i].days[c].countMark += 1;
              }
            }
          }
        }
      }
      return tempArr;
    },

    getDateMonthAndDay(marks) {
      let tempArr;
      let tempSet = new Set();
      for (let i = 0; i < marks.length; i++) {
        tempSet.add(this.marks[i].date);
      }
      tempArr = Array.from(tempSet);
      tempSet.clear();
      let tempSymbol = "";
      for (let i = 0; i < tempArr.length; i++) {
        tempSymbol =
          tempArr[i][3] +
          tempArr[i][4] +
          tempArr[i][2] +
          tempArr[i][0] +
          tempArr[i][1] +
          tempArr[i][5] +
          tempArr[i][6] +
          tempArr[i][7] +
          tempArr[i][8] +
          tempArr[i][9];

        tempSet.add(new Date(tempSymbol.replace(/\./g, "/")));
      }
      tempArr = Array.from(tempSet);
      return tempArr;
    },

    smashDates(dates) {
      let tempArr = [];
      let tempSet = new Set();
      for (let i in dates) {
        tempSet.add(dates[i].getMonth());
      }

      tempSet = Array.from(tempSet);
      for (let i in tempSet) {
        let tempObj = {
          day: [],
          monthName: this.getNameMonth(tempSet[i]),
          month: tempSet[i],
        };
        tempArr.push(tempObj);
      }

      for (let i in dates) {
        for (let j in tempArr) {
          if (dates[i].getMonth() === tempArr[j].month) {
            tempArr[j].day.push(dates[i].getDate());
            tempArr[j].day.sort((a, b) => (a > b ? 1 : -1));
          }
        }
      }

      for (let i in tempArr) {
        for (let j = 0; j < tempArr[i].day.length; j++)
          this.days.push({ day: tempArr[i].day[j], month: tempArr[i].month });
      }

      return tempArr;
    },

    getNameMonth(tempMonth) {
      if (tempMonth == 0) {
        tempMonth = "Январь";
      }
      if (tempMonth == 1) {
        tempMonth = "Февраль";
      }
      if (tempMonth == 2) {
        tempMonth = "Март";
      }
      if (tempMonth == 3) {
        tempMonth = "Апрель";
      }
      if (tempMonth == 4) {
        tempMonth = "Май";
      }
      if (tempMonth == 5) {
        tempMonth = "Июнь";
      }
      if (tempMonth == 6) {
        tempMonth = "Июль";
      }
      if (tempMonth == 7) {
        tempMonth = "Август";
      }
      if (tempMonth == 8) {
        tempMonth = "Сентябрь";
      }
      if (tempMonth == 9) {
        tempMonth = "Октябрь";
      }
      if (tempMonth == 10) {
        tempMonth = "Ноябрь";
      }
      if (tempMonth == 11) {
        tempMonth = "Декабрь";
      }
      return tempMonth;
    },
  },
};
</script>

<style lang="scss" scoped>
tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}

TABLE {
  width: 300px; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
}
TD,
TH {
  padding: 2px; /* Поля вокруг содержимого таблицы */
  border: 0.5px solid rgb(24, 0, 130); /* Параметры рамки */
}
.table {
  width: 80%;
  cursor: default;
  contain: paint;
}

.table tr {
  position: relative;
}

.table td {
  position: relative;
}
#THdays {
  top: 25px;
}
.table td:hover:after {
  pointer-events: none;
  position: absolute;
  z-index: 30;
  top: -5000px;
  left: 0;
  width: 100%;
  height: 10000px;
  content: "";
  contain: paint;
  background-color: rgba(95, 158, 160, 0.249);
}
tbody tr:hover {
  background: #6c9e54; /* Цвет фона при наведении */
  color: #fff; /* Цвет текста при наведении */
  z-index: 20;
}

TH {
  background: #b0e0e6; /* Цвет фона */
  position: sticky;
  padding: 0;
  top: 0;
  z-index: 220;
}
</style>
